import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import careersImg from '../../../../assets/images/careersImg.png'
import shadowImg from '../../../../assets/images/shadow.png'

const DriveWithUS = () => {
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${shadowImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          py: 5
        }}
      >
        <Container maxWidth={'xl'} sx={{ py: { xs: 10, sm: 20 }, px: { xs: 7, md: 10, lg: 20 } }}>
          <Grid container spacing={{ xs: 10, md: 10, lg: 0 }}>
            <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
              <Typography
                sx={{ color: '#9CE26E', fontSize: { xs: '16px', lg: '20px' }, fontWeight: 500, lineHeight: '27px' }}
              >
                Why Drive with us
              </Typography>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: { xs: '28px', md: '30px', lg: '48px' },
                  fontWeight: 600,
                  lineHeight: '57px',
                  mt: { lg: 3 }
                }}
              >
                Going fast, far, together
              </Typography>
              <Typography
                sx={{
                  color: '#B4B4B4',
                  fontSize: { xs: '16px', lg: '20px' },
                  fontWeight: 500,
                  lineHeight: { xs: '26px', md: '30px', lg: '36px' },
                  mt: { xs: 1, lg: 6 }
                }}
              >
                At ZanRide, we believe in creating a company culture that attracts top talent and cultivates
                excellence. If you're passionate about technology, innovation, and making a positive impact in
                people's lives, then we want YOU on our team! We offer a range of exciting career opportunities
                for individuals at different stages of their professional journey. With competitive compensation,
                growth potential, and a supportive work environment, ZanRide is the perfect place to take your
                career to the next level. Join us and be a part of the future of transportation!
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ order: { xs: 1, md: 2 }, display: 'flex', justifyContent: { xs: 'center', md: 'end' } }}
            >
              <Box
                sx={
                  {
                    // width: { sm: '587px', lg: '587px' }
                  }
                }
              >
                <img src={careersImg} style={{ width: '100%' }} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default DriveWithUS
