// ** MUI Imports
import Fab from '@mui/material/Fab'
import AppBar from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import MuiToolbar from '@mui/material/Toolbar'
// ** Icons Imports
import ArrowUp from 'mdi-material-ui/ArrowUp'
// ** Theme Config Import
import themeConfig from 'src/configs/themeConfig'

// ** Components
import Customizer from 'src/@core/components/customizer'
import Footer from './components/shared-components/footer'
import Navigation from './components/horizontal/navigation'
import ScrollToTop from 'src/@core/components/scroll-to-top'
import AppBarContent from './components/horizontal/app-bar-content'

// ** Styled Component
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'
// import Avatar from '../components/mui/avatar'
import { Box, Button, Typography, useScrollTrigger } from '@mui/material'
// import Logo from '../../assets/images/Logo.png'
import Translations from 'src/layouts/components/Translations'
import Bg from '../../assets/images/bg.png'
import React from 'react'

import { Link, useLocation } from 'react-router-dom'
import Logo from '../components/logo'
import { useState } from 'react'

const HorizontalLayoutWrapper = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  ...(themeConfig.horizontalMenuAnimation && { overflow: 'clip' })
})

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(0, 6)} !important`,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4)
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%'
  // padding: theme.spacing(6),
  // transition: 'padding .25s ease-in-out',
  // [theme.breakpoints.down('sm')]: {
  //   paddingLeft: theme.spacing(4),
  //   paddingRight: theme.spacing(4)
  // }
}))

const HorizontalLayout = props => {
  const location = useLocation()
  // ** Props
  const {
    hidden,
    children,
    settings,
    scrollToTop,
    saveSettings,
    horizontalNavMenuContent: userHorizontalNavMenuContent
  } = props

  // ** Vars
  const { skin, appBar, navHidden, appBarBlur, contentWidth } = settings

  function ChangeColorOnScroll(props) {
    const { children, window } = props
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined
    })

    return React.cloneElement(children, {
      style: { backgroundColor: trigger ? '#fff' : 'transparent', color: trigger ? '#000' : 'transparent' }
    })
  }

  const [openDialog, setOpenDailog] = useState(false)
  const handleDialogOpen = () => {
    setOpenDailog(true)
  }
  const handleClose = () => {
    setOpenDailog(false)
  }
  return (
    <HorizontalLayoutWrapper className='layout-wrapper'>
      <ChangeColorOnScroll {...props}>
        <AppBar
          color='default'
          // elevation={skin === 'bordered' ? 0 : 3}
          className='layout-navbar-and-nav-container'
          position={appBar === 'fixed' ? 'sticky' : 'static'}
          sx={{
            //borderBottom: '1px solid #6DA2A1',
            boxShadow: 'none',
            alignItems: 'center',
            color: 'text.primary',
            //bgcolor: '#fff !important',
            justifyContent: 'center'
            // ...(appBar === 'static' && { zIndex: 13 }),
            // ...(skin === 'bordered' && { borderBottom: theme => `1px solid ${theme.palette.divider}` }),
            // transition: 'border-bottom 0.2s ease-in-out, backdrop-filter .25s ease-in-out',
            // ...(appBar === 'fixed'
            //   ? appBarBlur && {
            //     backdropFilter: 'blur(8px)',
            //   }
            //   : {})
          }}
        >
          {/* <Box
            className='layout-navbar'
            sx={{
              width: '100%', bgcolor: '#0C7362', borderRadius: '10px', height: '41px',
              ...(navHidden ? {} : { borderBottom: theme => `1px solid ${theme.palette.divider}` })
            }}
          >
            <Toolbar
              className='navbar-content-container'
              sx={{
                mx: 'auto', height: '41px !important',
                ...(contentWidth === 'boxed' && { '@media (min-width:1440px)': { maxWidth: 1440 } }),
                // minHeight: theme => `${theme.mixins.toolbar.minHeight - 1}px !important`
              }}
            >
              <AppBarContent {...props} hidden={hidden} settings={settings} saveSettings={saveSettings} />
            </Toolbar>
          </Box> */}

          {navHidden ? null : (
            <Box
              className='layout-horizontal-nav'
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              sx={{ width: '100%', height: '90px', paddingLeft: '170px', backgroundColor: 'black' }}
            >
              {/* <img src={Logo} alt='logo' style={{ height: '19px', width: '97px' }} /> */}
              <Link to='/' style={{ textDecoration: 'none' }}>
                <Logo />
              </Link>
              <Box>
                <Toolbar
                  className='horizontal-nav-content-container'
                  sx={{
                    bgcolor: 'primary',
                    mx: 'auto',
                    height: '100%',
                    ...(contentWidth === 'boxed' && { '@media (min-width:1440px)': { maxWidth: 1440 } })
                    // minHeight: theme => `${theme.mixins.toolbar.minHeight - (skin === 'bordered' ? 1 : 0)}px !important`
                  }}
                >
                  {(userHorizontalNavMenuContent && userHorizontalNavMenuContent(props)) || <Navigation {...props} />}
                </Toolbar>
              </Box>
              <Box display='flex' flexDirection='row' alignItems='center' gap={3} pr='113px'>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: 10
                  }}
                >
                  <Button
                    variant='contained'
                    as={Link}
                    to='https://customer.zanride.com/login'
                    sx={{
                      textDecoration: 'none',
                      backgroundColor: '#ffff',
                      '&:hover': {
                        bgcolor: '#9CE26E'
                      },
                      borderRadius: 0,
                      padding: '3px 24px',
                      fontSize: '16px',
                      fontWeight: 500
                    }}
                  //onClick={handleDialogOpen}
                  >
                    Login
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </AppBar>
      </ChangeColorOnScroll>

      <ContentWrapper
        className='layout-page-content'
        sx={{
          ...(contentWidth === 'boxed' && {
            mx: 'auto',
            '@media (min-width:1440px)': { maxWidth: 1440 },
            '@media (min-width:1200px)': { maxWidth: '100%' }
          })
        }}
      >
        {children}
      </ContentWrapper>

      <Footer {...props} />

      <DatePickerWrapper sx={{ zIndex: 11 }}>
        <Box id='react-datepicker-portal'></Box>
      </DatePickerWrapper>

      {themeConfig.disableCustomizer || hidden ? null : <Customizer />}

      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <ArrowUp />
          </Fab>
        </ScrollToTop>
      )}

    </HorizontalLayoutWrapper>
  )
}

export default HorizontalLayout
