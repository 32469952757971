import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import careersBgImg from '../../../../assets/images/careersBgImg.png'

const BannerSection = () => {
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${careersBgImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: { xs: '100% 100%', lg: '100% 100%' },
          py: { xs: 10, sm: 10, md: 20, lg: 30, xl: 50 },
          px: { xs: 7, md: 10, lg: 20 }
        }}
      >
        <Container maxWidth={'xl'}>
          <Grid container spacing={{ md: 10 }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: { xs: '32px', sm: '64px' },
                  fontWeight: 600,
                  lineHeight: { xs: '56px', sm: '76px' },
                  color: '#fff'
                }}
              >
                Careers
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: { xs: '18px', sm: '20px' },
                  fontWeight: 500,
                  lineHeight: { xs: '26px', sm: '36px' },
                  color: '#fff'
                }}
              >
                Join our dynamic team and embark on a journey of innovation and growth.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default BannerSection
