import { isPercentage } from "src/utils/utils";

const Logo = ({ width = 180, height }) => {
    let renderHeight = height;
    let isWidthPercentage = isPercentage(width);
    if (!isWidthPercentage) {
        if (!renderHeight) {
            renderHeight = width / (180 / 38);
        } else if (!width || !height) {
            renderHeight = 38;
        }
    }

    return (
        <svg
            width="180"
            height="38"
            viewBox="0 0 180 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.769757 3.88208H23.3236V6.51466L7.85152 28.3758H23.3236V33.7025H0V30.4695L15.1334 9.03945H0.769757V3.88208Z"
                fill="#9CE26E"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M43.9069 12.7035H48.8949V33.7025H43.9839L43.7376 30.6389C42.5367 33.1175 39.2576 34.3029 36.9175 34.3491C30.6979 34.3953 26.0947 30.5619 26.0947 23.1876C26.0947 15.9365 30.898 12.1493 37.0407 12.1801C39.858 12.1801 42.5367 13.5041 43.7376 15.5978L43.9069 12.7035ZM31.2829 23.1876C31.2829 27.1904 34.054 29.5766 37.5025 29.5766C45.6928 29.5766 45.6928 16.8295 37.5025 16.8295C34.054 16.8295 31.2829 19.1849 31.2829 23.1876Z"
                fill="#9CE26E"
            />
            <path
                d="M70.1095 33.7024V22.7103C70.1095 19.5235 68.3698 17.0911 65.0445 17.0911C61.8423 17.0911 59.6716 19.7698 59.6716 22.972V33.7024H54.5142V12.6573H59.1635L59.5022 15.5054C61.6267 13.427 63.7667 12.3494 66.3992 12.3494C71.3565 12.3494 75.313 16.0596 75.313 22.6641V33.7024H70.1095Z"
                fill="#9CE26E"
            />
            <path
                d="M136.817 17.0449C133.491 17.0449 130.843 19.385 130.843 23.1876C130.843 26.8516 133.491 29.361 136.817 29.361C140.096 29.361 142.898 26.9748 142.898 23.1876C142.898 19.5235 140.096 17.0449 136.817 17.0449Z"
                fill="#9CE26E"
            />
            <path
                d="M164.174 16.6599C161.356 16.6599 159.063 18.0301 158.293 20.755H169.624C169.285 18.0301 167.16 16.6599 164.174 16.6599Z"
                fill="#9CE26E"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M180 0.479736V37.5204H116.218L103.44 23.1414C109.105 21.9868 110.984 17.8609 110.984 13.7657C110.984 8.60837 107.273 3.91285 100.238 3.88206C95.5115 3.88206 90.7852 3.83588 86.0435 3.83588V33.7025H91.6781V9.11641H100.238C103.779 9.11641 105.349 11.5027 105.349 13.8889C105.349 16.2752 103.825 18.6614 100.238 18.6614H92.9405L97.3743 23.7418H97.3897L109.475 37.5204H80.7629V0.479736H180ZM121.252 12.6111H116.064V33.7025H121.252V12.6111ZM121.729 6.77634C121.729 2.72742 115.587 2.72742 115.587 6.77634C115.587 10.8253 121.729 10.8253 121.729 6.77634ZM148.455 3.92825H143.252V15.567C142.005 13.3809 138.556 12.1954 136.339 12.1954C130.212 12.1954 125.64 15.9365 125.64 23.1876C125.64 30.1 130.289 34.1797 136.478 34.1797C139.034 34.1797 141.635 33.333 143.252 30.8236L143.591 33.7178H148.455V3.92825ZM164.004 12.0723C157.184 12.0723 152.843 16.6754 152.843 23.0644C152.843 29.7921 157.138 34.2721 164.389 34.2721C167.592 34.2721 171.209 33.1636 173.426 30.8697L170.101 27.5752C168.9 28.8222 166.391 29.5458 164.466 29.5458C160.802 29.5458 158.555 27.6676 158.201 25.0658H174.612C175.428 16.4137 171.04 12.0723 164.004 12.0723Z"
                fill="#9CE26E"
            />
        </svg>
    );
};

export default Logo;
