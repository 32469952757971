import React from 'react'
import Fab from '@mui/material/Fab'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'

function TabComponent() {
  return <></>
}

export default TabComponent
