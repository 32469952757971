import React from 'react'
import BannerSection from 'src/views/pages/Careers/bannerSection'
import DriveWithUS from 'src/views/pages/Careers/driveWithUs'
import SEOspecialist from 'src/views/pages/Careers/seoSpecialist'

const CareersPage = () => {
  return (
    <>
      <BannerSection />
      <DriveWithUS />
      <SEOspecialist />
    </>
  )
}

export default CareersPage
