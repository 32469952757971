import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton
} from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import shadowImg from '../../../../assets/images/shadow.png'
import { Link } from 'react-router-dom'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'

const SEOspecialist = () => {
  const [careerdata, setCareerData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    setLoading(true)
    axiosInstance
      .get(ApiEndpoints.CAREER.list)
      .then(response => {
        setCareerData(response.data.data.careers)
      })
      .catch(error => {})
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])
  const [openSEOIcon, setOpenSEOIcon] = useState(false)

  const handleSEOChange = id => {
    // setOpenFAQIcon(prev => ({
    //   ...prev,
    //   [id]: !prev[id]
    // }))
    setOpenSEOIcon(!openSEOIcon)
  }
  return (
    <>
      <Box>
        <Container maxWidth={'xl'} sx={{ px: { xs: 7, md: 10, lg: 20 } }}>
          <Grid container rowSpacing={{ xs: 4, md: 0 }} columnSpacing={{ md: 4 }}>
            <Grid item container rowGap={4} md={8}>
              {careerdata.map((item, index) => (
                <Grid item sx={{ bgcolor: '#202020' }} maxHeight={'50vh'} width='100%' overflow={'auto'}>
                  <Accordion sx={{ boxShadow: 'none' }} key={index} onChange={() => handleSEOChange}>
                    <AccordionSummary
                      expandIcon={
                        openSEOIcon ? (
                          <IconButton
                            sx={{
                              border: '1px solid #9CE26F',
                              p: { xs: '10px 5px', md: '13px 7px', lg: '15px 8px ' },
                              borderRadius: '40px'
                            }}
                          >
                            <ArrowDownwardIcon sx={{ fontSize: '30px', color: '#9CE26F' }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            variant='outlined'
                            sx={{
                              border: '1px solid #9CE26F',
                              p: { xs: '10px 5px', md: '13px 7px', lg: '15px 8px ' },
                              borderRadius: '40px'
                            }}
                          >
                            <ArrowUpwardIcon sx={{ fontSize: '30px', color: '#9CE26F' }} />
                          </IconButton>
                        )
                      }
                      aria-controls='panel1-content'
                      id='panel1-header'
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            component={'h1'}
                            sx={{
                              fontSize: { xs: '18px', sm: '20px', lg: '24px' },
                              fontWeight: 600,
                              lineHeight: { xs: '32px', sm: '34px', lg: '38px' },
                              color: '#9CE26F'
                            }}
                            gutterBottom
                          >
                            {item.role}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography component={'div'} sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '25px' }}>
                            {item?.Category?.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography component={'div'} sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '25px' }}>
                            {item?.location}
                            {/* Fulltime | Remote */}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container rowGap={{ xs: 4, lg: 6 }}>
                        <Grid item xs={12}>
                          <Typography noWrap variant='body2' title={item?.description}>
                            <div
                              className='blog_____content'
                              dangerouslySetInnerHTML={{
                                __html: item?.description
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
            <Grid item container md={4}>
              <Grid item sx={{ bgcolor: '#202020', p: 5 }}>
                <Typography
                  sx={{
                    fontSize: { xs: '18px', sm: '20px', lg: '24px' },
                    fontWeight: 600,
                    lineHeight: { xs: '32px', sm: '34px', lg: '38px' },
                    color: '#F2F2F2'
                  }}
                  gutterBottom
                >
                  Join our team today!
                </Typography>
                <Typography
                  sx={{ fontSize: { lg: '20px' }, fontWeight: 500, lineHeight: { lg: '36px' }, color: '#B4B4B4' }}
                  gutterBottom
                >
                  "Explore exciting career opportunities with us and take the next step towards building your future in
                  digital innovation.
                </Typography>
                <Link to={'/contact'}>
                  <Button
                    fullWidth
                    variant='contained'
                    sx={{
                      bgcolor: '#ffff',
                      color: '#0E0E0E',
                      borderRadius: 0,
                      fontWeight: 500,
                      textDecoration: 'none'
                    }}
                  >
                    Apply Now
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                {/* <Box sx={{ width: '300px', opacity: 0.5 }}>
                  <img src={shadowImg} alt='' style={{ width: '100%' }} />
                </Box> */}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default SEOspecialist
