// ** React Imports
import { Fragment } from 'react'

// ** Next Imports
import { Link, useLocation, useSearchParams } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import List from '@mui/material/List'
import { styled } from '@mui/material/styles'
import ListItemIcon from '@mui/material/ListItemIcon'
import MuiListItem from '@mui/material/ListItem'

// ** Third Party Imports
import clsx from 'clsx'

// ** Theme Config Import
import themeConfig from 'src/configs/themeConfig'

// ** Custom Components Imports
import UserIcon from 'src/layouts/components/UserIcon'
import Translations from 'src/layouts/components/Translations'
import CanViewNavLink from 'src/layouts/components/acl/CanViewNavLink'

// ** Util Import
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'
import { Button, Typography } from '@mui/material'

const ListItem = styled(MuiListItem)(({ theme }) => ({
  width: 'auto',
  paddingTop: theme.spacing(2.25),
  color: "#fff",
  paddingBottom: theme.spacing(2.25),
  '&:hover': {
    // backgroundColor: theme.palette.action.hover
    //color: '#9CE26E'
  },
  '&.active, &.active:hover': {
    //backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08)
    color: '#9CE26E'
  },
}))

const HorizontalNavLink = props => {
  // ** Props
  const { item, settings, hasParent } = props


  // ** Hook & Vars
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const { menuTextTruncate } = themeConfig
  const Wrapper = !hasParent ? List : Fragment

  const handleURLQueries = () => {
    if (Object.keys(searchParams).length && item.path) {
      const arr = Object.keys(searchParams)

      return location.pathname.includes(item.path) && location.pathname.includes(searchParams[arr[0]])
    }
  }

  const isNavLinkActive = () => {
    if (location.pathname === item.path || handleURLQueries()) {
      return true
    } else {
      return false
    }
  }


  return (
    // <CanViewNavLink navLink={item}>
    <Wrapper
    // {...(!hasParent
    //   ? {
    //       component: 'div',
    //       sx: { borderBottom: 2, borderColor: 'common.white' }
    //     }
    //   : {})}
    >

      {/* <Link to={`${item.path}`}> */}
      <ListItem
        to={`${item.path}`}
        component={Link}
        disabled={item.disabled}
        className={clsx({ active: isNavLinkActive() })}
        target={item.openInNewTab ? '_blank' : undefined}
        onClick={e => {
          if (item.path === undefined) {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
        sx={{
          ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
          ...(!hasParent
            ? {
              height: '45px',
              width: '100%',
              px: 5.5,
              borderRadius: '10px',
              '&.active, &.active:hover': {
                // boxShadow: 3,
                backgroundColor: '',
                '& .MuiButton-root, & .MuiListItemIcon-root': {
                  color: 'common.white'
                }
              }
            }
            : { px: 5 })
        }}
      >

        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              ...(menuTextTruncate && { overflow: 'hidden' })
            }}
          >

            {/* <Button variant="text" sx={{ fontWeight: 600, lineHeight: '21px' }}> */}
            <Translations text={item.title} />
            {/* </Button> */}
          </Box>
        </Box>

      </ListItem>

      {/* </Link> */}
    </Wrapper>
    // </CanViewNavLink>
  )
}

export default HorizontalNavLink
