import { List } from "@mui/material"

export const API_BASE_URL = 'https://api.zanride.com'
//export const API_BASE_URL = 'http://192.168.29.54:4000'
export const MEDIA_URL = 'https://zan-rides-assets.s3.af-south-1.amazonaws.com'

export const ApiEndpoints = {
  PRIVACY_POLICY: {
    list: `${API_BASE_URL}/api/v1/misc/legalcontent/privacy_policy`
  },
  TERMS: {
    list: `${API_BASE_URL}/api/v1/misc/legalcontent/terms_and_conditions`
  },
  CONTACTUS: {
    add: `${API_BASE_URL}/api/v1/misc/contact/us`
  },
  FAQ: {
    list: `${API_BASE_URL}/api/v1/misc/faqs`
  },
  TESTIMONIAL: {
    list: `${API_BASE_URL}/api/v1/misc/testimonials`
  },
  CAREER: {
    list: `${API_BASE_URL}/api/v1/misc/careers`
  },
  BLOG: {
    list: `${API_BASE_URL}/api/v1/misc/blogs`,
    listbyid: id => `${API_BASE_URL}/api/v1/misc/blogs/${id}`
  },
  CATEGORY: {
    list: `${API_BASE_URL}/api/v1/misc/vehicle_categories`
  },
  FOOTER: {
    list: `${API_BASE_URL}/api/v1/misc/footer`
  }
}
