// ** Icon imports

const navigation = () => {
  return [
    {
      title: 'Home',
      path: '/'
    },
    {
      title: 'Become A Driver',
      path: '/become-a-driver'
    },

    {
      title: 'About Us',
      path: '/about-us'
    },
    {
      title: 'Careers',
      path: '/careers'
    },
    {
      title: 'Blogs',
      path: '/blog'
    },
    {
      title: 'Contact Us',
      path: '/contact'
    }
  ]
}

export default navigation
